import React from "react";
import tw, { styled } from "twin.macro";
import { Link as Glink } from "gatsby";

const TitleBlock = styled.div(({ videoLibrary }) => [
  tw`xl:mt-44 md:px-12 flex flex-col items-center px-4 mt-32 mb-12`,
  videoLibrary && tw`bg-dark pt-32 pb-12 my-0!`,
]);

const Breadcrumbs = styled.div(({ videoLibrary }) => [
  tw`flex self-start text-sm [span]:mx-2`,
  videoLibrary && tw`text-white`,
]);

const Title = styled.h1`
  ${tw`text-primary font-mazuis md:text-6xl lg:text-7xl py-12 text-5xl italic leading-none text-center`}
`;
const Spacer = styled.div(({ videoLibrary }) => [
  tw`
	flex flex-col items-center self-center w-px relative bg-[#081222] h-[3.5rem] md:h-[7rem] bg-opacity-20
	after:(content[''] bg-[#081222] w-[3px] h-[3px] rounded-full -translate-y-3 bg-opacity-20)
	before:(content[''] bg-[#081222] w-[20rem] h-px top-3 absolute bg-opacity-20)
`,

  videoLibrary && tw`after:bg-white before:bg-white bg-white`,
]);
const Link = tw(Glink)`hover:underline`;

const InnerTitleBlock = ({ header, videoLibrary, breadcrumbs, ...rest }) => {
  return (
    <TitleBlock videoLibrary={videoLibrary} {...rest}>
      {breadcrumbs &&
        breadcrumbs.length > 0 &&
        Array.isArray(breadcrumbs) &&
        !videoLibrary && (
          <Breadcrumbs videoLibrary={videoLibrary}>
            {breadcrumbs.map(({ url, name }, i) => (
              <div key={i}>
                <Link to={url}>{name}</Link>
                {i !== breadcrumbs.length - 1 && <span>/</span>}
              </div>
            ))}
          </Breadcrumbs>
        )}
      {header && (
        <>
          <Title>{header}</Title>
          <Spacer videoLibrary={videoLibrary} />
        </>
      )}
    </TitleBlock>
  );
};

export default InnerTitleBlock;
