import React from "react";
import tw from "twin.macro";

import VideoLibSingle from "./VideoLibSingle";

const Section = tw.section`w-full relative bg-dark grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 px-4 lg:(px-12 gap-12)`;

const VideoLib = ({ videos, ...rest }) => {
  return (
    <Section {...rest}>
      {videos.map((video, i) => (
        <VideoLibSingle key={i} {...video} />
      ))}
    </Section>
  );
};

export default VideoLib;
