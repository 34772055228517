import React, { useRef, useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { useInView } from "react-intersection-observer";

import VideoShimmer from "./VideoShimmer";

const Wrapper = styled.div(({ inview }) => [
  tw`flex flex-col items-center w-full py-4 translate-y-[50px] duration-1000 justify-end ease-in-out opacity-0 scale-95`,
  inview && tw`overflow-hidden scale-100 translate-y-0 rounded-lg opacity-100`,
]);
const Title = tw.h3`text-3xl md:text-4xl font-mazuis italic text-center text-primary mb-3`;
const VideoWrapper = tw.div`w-full shadow-2xl relative`;
const Video = tw.div`rounded-lg overflow-hidden`;

const VideoLibSingle = ({ title, embed, ...rest }) => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [observer, inView] = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    const iframe = videoRef.current.querySelector("iframe");

    if (iframe) {
      iframe.onload = () => {
        setIsLoaded(true);
      };
    }
  }, []);

  return (
    <Wrapper {...rest} ref={observer} inview={inView}>
      <Title>{title}</Title>
      <VideoWrapper ref={videoRef}>
        <Video dangerouslySetInnerHTML={{ __html: embed }} />
        {!isLoaded && <VideoShimmer />}
      </VideoWrapper>
    </Wrapper>
  );
};

export default VideoLibSingle;
