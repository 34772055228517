import React from "react";
import tw, { styled, css } from "twin.macro";

const Wrapper = styled.div(() => [
  tw`bg-[#343c44] absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden`,
]);

const Shimmer = styled.div(() => [
  tw`bg-opacity-20 blur-md absolute top-0 w-12 h-full bg-black`,

  css`
    animation: shimmer 1s forwards infinite;

    @keyframes shimmer {
      from {
        ${tw`left-[-3rem]`}
      }
      to {
        ${tw`left-full`}
      }
    }
  `,
]);

const VideoShimmer = ({ ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Shimmer />
    </Wrapper>
  );
};

export default VideoShimmer;
