/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import { getBreadcrumbs } from "../../lib/functions";

import InnerTitleBlock from "../components/InnerTitleBlock";
import VideoLib from "../components/VideoLib";
import Layout from "../components/Layout";

const Page = ({ data }) => {
  const { wpPage: page } = data;
  const { title, content, seo, pageSettings, videoLibrary } = page;

  return (
    <Layout seo={seo} inner schemaCodes={pageSettings.schemaCodes}>
      <InnerTitleBlock
        videoLibrary
        header={pageSettings?.header ? pageSettings?.header : title}
        breadcrumbs={getBreadcrumbs(seo)}
      />
      <VideoLib videos={videoLibrary.videos} />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      pageSettings {
        header
        formTitle
        schemaCodes {
          singleSchema
        }
      }
      videoLibrary {
        videos {
          title
          embed
        }
      }
    }
  }
`;
